/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import {
  getAllCompanies,
  getOneCompanyByIdRef,
  getAllCompaniesByFilter,
} from '@/shared/services/index';
import PropTypes from 'prop-types';

const CompanyContext = createContext({});

const CompanyProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [companies, setCompanies] = useState([]);
  const [companies, setCompanies] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });

  const [company, setCompany] = useState(null);

  const loadCompanies = async () => {
    setIsLoading(true);
    try {
      const { payload } = await getAllCompanies();
      setCompanies(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCompaniesByFilter = async (filterParams = '') => {
    setIsLoading(true);
    try {
      const { payload } = await getAllCompaniesByFilter(filterParams);
      setCompanies(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCompanyByIdRef = async (idRef) => {
    setCompany(null);
    setIsLoading(true);
    try {
      const { payload } = await getOneCompanyByIdRef(idRef);
      setCompany(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setCleanCompany = () => {
    setCompany(null);
  };


  const valuesContext = {
    isLoading,
    companies,
    company,
    // Functions
    loadCompanies,
    loadCompaniesByFilter,
    loadCompanyByIdRef,
    setCleanCompany,
  };

  return (
    <CompanyContext.Provider value={valuesContext}>
      {children}
    </CompanyContext.Provider>
  );
};

CompanyProvider.propTypes = {
  children: PropTypes.node,
};

const useCompany = () => useContext(CompanyContext);

export { CompanyProvider, useCompany };
