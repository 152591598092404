import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmailConfirmation from '../../../Account/EmailConfimation/index';
import LogIn from '../../../Account/LogIn/index';
import Register from '../../../Account/Register/index';
import ResetPassword from '../../../Account/ResetPassword/index';

export default () => (
  <Switch>
    <Route path="/" component={LogIn} />
    <Route path="/account/register" component={Register} />
    <Route path="/account/reset_password" component={ResetPassword} />
    <Route path="/account/email_confirmation" component={EmailConfirmation} />
  </Switch>
);
