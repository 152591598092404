/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import {
  getAllChannelsByFilter, getAllChannelsByCompanyId, getChannelByIdRef, putChannel,
} from '@/shared/services/index';
import PropTypes from 'prop-types';
import { connectChannel } from '../shared/services/channels';

const ChannelContext = createContext({});

const ChannelProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allChannels, setAllChannels] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });
  const [channels, setChannels] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });
  const [channel, setChannel] = useState(null);

  const loadChannelsByFilter = async () => {
    setIsLoading(true);
    try {
      const { payload } = await getAllChannelsByFilter();
      setAllChannels({
        allChannels,
        data: payload.data.dados,
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadChannelsByCompanyId = async (companyId, filterParams = '') => {
    setIsLoading(true);
    try {
      const { payload } = await getAllChannelsByCompanyId(companyId, filterParams);
      setChannels(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadChannelByIdRef = async (channelIdRef) => {
    setIsLoading(true);
    try {
      const { payload } = await getChannelByIdRef(channelIdRef);
      setChannel(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateChannel = async (channelIdRef, data) => {
    setIsLoading(true);
    try {
      await putChannel(channelIdRef, data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };


  const tryConnectChannel = async (channelId) => {
    setIsLoading(true);
    try {
      const { payload } = await connectChannel(channelId);

      if (payload.data === 'failed') throw new Error('Failed to connect channel');
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const valuesContext = {
    isLoading,
    allChannels,
    channels,
    channel,
    // Functions
    loadChannelsByFilter,
    loadChannelsByCompanyId,
    loadChannelByIdRef,
    updateChannel,
    tryConnectChannel,
  };

  return (
    <ChannelContext.Provider value={valuesContext}>
      {children}
    </ChannelContext.Provider>
  );
};

ChannelProvider.propTypes = {
  children: PropTypes.node,
};

const useChannel = () => useContext(ChannelContext);

export { ChannelProvider, useChannel };
