/* eslint-disable react/jsx-indent */
import React from 'react';
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';
import ClockCheckIcon from 'mdi-react/ClockCheckIcon';
import CancelBoxIcon from 'mdi-react/CancelBoxIcon';
import classNames from 'classnames';



const CardChargeItem = ({
    dueAt, paidAt, price, paymentMethod, status,
}) => (
    <div className="card-charge">
        <div className="block-charge">
            <span>Vencimento</span>
            <span>{dueAt}</span>
        </div>

        <div className="block-charge">
            <span>Pago</span>
            <span>{paidAt}</span>
        </div>

        <div className="block-charge">
            <span>Forma de Pagamento</span>
            <span>{paymentMethod}</span>
        </div>

        <div className="block-charge">
            <span>{price}</span>
        </div>

        <div className={classNames(
            'icon-charge',
            {
                'icon-charge-success': status === 'paid',
                'icon-charge-warning': status === 'pending',
                'icon-charge-danger': status === 'canceled',
            },
        )}
        >
            {status === 'paid' && <CheckBoxIcon />}
            {status === 'pending' && <ClockCheckIcon />}
            {status === 'canceled' && <CancelBoxIcon />}
        </div>
    </div>
);

export default React.memo(CardChargeItem);
