import React from 'react';
import PropTypes from 'prop-types';
import LogInForm from '@/shared/components/loginForm/LogInForm';
import showResults from '@/utils/showResults';
import { useAuth } from '@/hooks/useAuth';


const LogIn = () => {
  const { handleLogin, error } = useAuth();
  const handleSubmit = async (values) => {
    try {
      await handleLogin(values.username, values.password);
    } catch (err) {
      showResults(err);
    }
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Bem vindo ao
              <span className="account__logo"> Ihelp
                <span className="account__logo-accent">ADM</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Comece seu trabalho mais facil</h4>
          </div>
          <LogInForm
            onSubmit={handleSubmit}
            form="log_in_form"
            errorMessage={error && 'Nao foi possivel realizar o login. Verifique se preencheu corretamente os campos.'}
          />


        </div>
      </div>
    </div>
  );
};


export default LogIn;
