/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { values as valueFormat } from 'lodash';

const renderTextField = ({
  input, label, meta: { touched, error },
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};


function validatePassword(formValues = '', value) {
  if (value && value.length <= 8) return 'Senha deve ter no mínimo 8 caracteres';

  return undefined;
}

const EmployeeSettings = ({ onSubmit, initialValues }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({
      handleSubmit, form, errors, values,
    }) => (
      <form className="material-form custom-form" onSubmit={handleSubmit}>
        {valueFormat(errors).map((error, index) => (
          <Alert
            color="danger"
            isOpen={!!valueFormat(errors)}
          >
            {error}
          </Alert>
        ))}

        <div>
          <span className="material-form__label">Nome</span>
          <Field
            validate={value => (value ? undefined : 'Nome é obrigatorio')}
            name="name"
            component={renderTextField}
            placeholder="Digite seu nome"
          />
        </div>

        <div>
          <span className="material-form__label">Email</span>
          <Field
            name="email"
            validate={value => (value ? undefined : 'Email é obrigatorio')}
            component={renderTextField}
            placeholder="Digite seu email"
          />
        </div>

        <div>
          <span className="material-form__label">Telefone</span>
          <Field
            validate={value => (value ? undefined : 'Celular é obrigatorio')}
            name="phoneNumber"
            component={renderTextField}
            placeholder="Digite seu telefone"
          />
        </div>

        <div>
          <span className="material-form__label">Senha</span>
          <Field
            name="passwordHash"
            validate={value => validatePassword(values, value)}
            component={renderTextField}
            placeholder="Digite uma senha"
          />
        </div>

        <div>
          <span className="material-form__label">Confirma Senha</span>
          <Field
            name="confirmPasswordHash"
            validate={value => ((value === values.passwordHash) ? undefined : 'As senhas não coincidem')}
            component={renderTextField}
            placeholder="Confirme a senha"
          />
        </div>

        {/* <div className="employee-form">

          <div className="card__title">
            <h5 className="bold-text">Departamentos do usuário</h5>
          </div>

          <div className="employee-checkbox-group">
            {values.userDepartments.map(item => (
              <div key={`index_${item.IdRef}`} className="form__form-group form__form-group-field">
                <Field
                  name={`${item.Titulo}`}
                  type="checkbox"
                  component={renderCheckBoxField}
                  label={item.Titulo}
                  initialValue={item.Checked}
                  disabled={false}
                  className="colored-click"
                />
              </div>
            ))}

          </div>
        </div> */}

        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">Atualizar Perfil</Button>
          <Button type="button" onClick={form.reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    )}
  </Form>
);

EmployeeSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EmployeeSettings;
