import React, { useMemo } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { useAttendance } from '@/hooks/useAttendance';

import moment from 'moment';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const AttendanceMain = () => {
  const { attendance } = useAttendance();

  const DateLastMessage = useMemo(() => moment(attendance.ChatUltimaMensagemData).format('DD/MM HH:mm'), [attendance]);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{attendance.ChatNome ?? 'Sem nome'}</p>
              <p className="profile__work">{attendance.ChatIdExterno}</p>
       
            </div>
          </div>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{attendance.ChatNotificacoes}</p>
              <p className="profile__stat-title">Notificacoes</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{attendance.Ativo ? 'Sim' : 'Não'}</p>
              <p className="profile__stat-title">Ativo</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{DateLastMessage}</p>
              <p className="profile__stat-title">Ultima Mensagem</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
);
  };
export default AttendanceMain;
