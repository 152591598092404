/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';

import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon';
import ConnectionIcon from 'mdi-react/ConnectionIcon';
import classNames from 'classnames';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const styleActions = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
};

const EmployeeCard = ({
  name, status, contact, link, connect,
}) => (
  <div className="project-member">
    <div
      className={classNames(
        'project-member__avatar-wrap',
        {
          color__status__active: status,
          color__status__desactive: !status,
        },
      )}
    >
      <img src={Ava} alt="imagem do empregado" />
    </div>
    <div>
      <p className="project-member__name">{name}</p>
      <p className="project-member__post">{status}</p>
      <p className="project-member__post">{contact}</p>
    </div>

    <div className="projec-member-actions">
      <Link
        className="project-member__btn btn btn-outline-primary btn-sm icon"
        to={link}
      ><ArrowRightBoxIcon />
      </Link>

      <span
        className="project-member__btn btn btn-outline-primary btn-sm icon"
        onClick={connect}
      ><ConnectionIcon />
      </span>
    </div>
  </div>
);



export default EmployeeCard;

