/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import { login } from '@/shared/services/index';
import PropTypes from 'prop-types';
import apiCustom from '../utils/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(() => {
        const token = localStorage.getItem('@ihelpToken');
        const user = localStorage.getItem('@ihelpUser');
        if (token && user) {
            return {
                access_token: token,
                isAuthenticated: true,
                user: JSON.parse(user),
            };
        }
        return {
            access_token: null,
            isAuthenticated: false,
            user: null,
        };
    });

    const [error, setError] = useState(false);

    const handleLogin = async (email, password) => {
        setIsLoading(true);
        try {
            const { payload } = await login(
                email,
                password,
            );

            localStorage.setItem('@ihelpToken', payload.data.access_token);
            localStorage.setItem('@ihelpUser', JSON.stringify(payload.data.user));

            apiCustom.defaults.headers.common.Authorization = `Bearer ${payload.data.access_token}`;

            setAuth({
                isAuthenticated: true,
                access_token: payload.data.access_token,
                user: payload.data.user,
            });
        } catch (err) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('@ihelpToken');
        localStorage.removeItem('@ihelpUser');
        setAuth({
            isAuthenticated: false,
            access_token: null,
        });
    };

    const valuesContext = {
        isLoading,
        error,
        auth,
        // Functions
        handleLogin,
        handleLogout,
    };

    return (
        <AuthContext.Provider
            value={valuesContext}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
