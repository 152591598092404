/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown,
} from 'reactstrap';
import { useChannel } from '@/hooks/useChannel';
import classNames from 'classnames';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import TopbarSearch from '../../../Layout/components/topbar/TopbarSearch';

const header = [
  { id: 0, title: '' },
  { id: 1, title: 'Titulo' },
  { id: 2, title: 'Id Contato' },
  { id: 3, title: 'Status' },
  { id: 4, title: 'Servidor' },
];

const styleFilter = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

const _ListChannels = () => {
  const { allChannels } = useChannel();
  const [isConnect, setIsConnect] = useState(true);
  const [isFilterByName, setIsFilterByName] = useState('');

  const handleFilter = async (dataFilter) => {
    setIsConnect(dataFilter);
  };

  const handleSearchChange = (e) => {
    setIsFilterByName(e.target.value);
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          className="icon icon--right"
          outline
          size="sm"
        >
          <p>{isConnect ? 'Conectados' : 'Desconectados'} <MenuDownIcon /></p>
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu">
          <DropdownItem onClick={() => handleFilter(true)}>Connectados</DropdownItem>
          <DropdownItem onClick={() => handleFilter(false)}>Desconectados</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <div className="mb-4">
        <TopbarSearch handleSearchChange={handleSearchChange} />
      </div>

      <Table responsive className="table--bordered">
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allChannels?.data?.filter(channel => !channel.desconectado === isConnect).filter(channel => channel.titulo.toLowerCase().includes(isFilterByName)).map((item, index) => (
            <tr key={String(item.idContato)}>
              <td>
                <div
                  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                  className={classNames(
                    'project-member__avatar-wrap',
                    {
                      color__status__active: !item.desconectado,
                      color__status__desactive: item.desconectado,
                    },
                  )}
                >
                  {item.titulo.toUpperCase().slice(0, 2)}
                </div>
              </td>
              <td>{item.titulo}</td>
              <td>{item.idContato}</td>
              <td>{!item.desconectado ? 'Conectado' : 'Desconectado'}</td>
              <td>{item.webDriverUri}</td>
            </tr>
          ))}
        </tbody>
      </Table>

    </>
  );
};

const ListChannels = React.memo(_ListChannels);

export default ListChannels;
