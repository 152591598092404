/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';


export const getAllChannelsByFilter = async (filterParams) => {
    try {
        const { data } = await apiCustom.get('/channels/filter');
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAllChannelsByCompanyId = async (companyId, filterParams) => {
    try {
        const { data } = await apiCustom.get(`/channels/${companyId}`, {
            params: {
                ...filterParams,
                limit: 5,
            },
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getChannelByIdRef = async (channelIdRef) => {
    try {
        const { data } = await apiCustom.get(`/channels/byIdRef/${channelIdRef}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};


export const putChannel = async (channelIdRef, dataChannel) => {
    try {
        const { data } = await apiCustom.put(`/channels/byIdRef/${channelIdRef}`, dataChannel);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const connectChannel = async (channelId) => {
    try {
        const { data } = await apiCustom.get(`/channels/connect/${channelId}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};
