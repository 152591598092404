import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackButton from '@/shared/components/button/BackButton';
import { useSubscription } from '@/hooks/useSubscription';
import { useCompany } from '@/hooks/useCompany';
import CardData from './components/CardData';
import ListProductItem from './components/ProductItems/ListProductItem';
import ListChagesItems from './components/ChargeItems/ListChagesItems';

const STATUS_IMPLE = 'IHELP_IMPLEMENT';

const SubscriptionProfilePage = () => {
  const isInitialMount = useRef(true);
  const {
    subscription, loadChargesByCustomerId, charges, resetContext,
  } = useSubscription();
  const { company } = useCompany();

  const memoNamePlan = useMemo(() => subscription?.plan.name, [subscription]);
  const memoPaymentMethod = useMemo(() => subscription?.payment_method.public_name, [subscription]);
  const memoEndDate = useMemo(() => moment(subscription?.current_period?.end_at).format('DD/MM/YYYY'), [subscription]);

  const memoSumPlan = useMemo(() => {
    const sum = subscription?.product_items.reduce((acc, item) => {
      if (item.product.code !== STATUS_IMPLE) {
        return acc + Number(item.pricing_schema.price);
      }
      return acc;
    }, 0);
    return sum;
  }, [subscription]);

  useEffect(() => {
    if (isInitialMount.current) {
      loadChargesByCustomerId(company.CustomerID);
    }

    return () => {
      resetContext();
      isInitialMount.current = false;
    };
  }, [company]);

  return (
    <Container>
      <BackButton />
      <div className="dashboard">
        <Row>
          {/* Nome do Plano */}
          <Col md={12} xl={3} lg={6} xs={12}>
            <CardData
              title="Nome do Plano"
              typeIcon="text"
              value={memoNamePlan}
            />
          </Col>

          <Col md={12} xl={3} lg={6} xs={12}>
            <CardData
              title="Fim da vigência"
              value={memoEndDate}
            />
          </Col>

          <Col md={12} xl={3} lg={6} xs={12}>
            <CardData
              title="Forma de Pagamento"
              typeIcon="credit"
              value={memoPaymentMethod}
            />
          </Col>

          <Col md={12} xl={3} lg={6} xs={12}>
            <CardData
              title="Valor do Plano"
              typeIcon="price"
              value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(memoSumPlan)}
            />
          </Col>
        </Row>

        <Row>
          <ListChagesItems charges={charges} />
          <ListProductItem productItems={subscription?.product_items} />
        </Row>
      </div>
    </Container>
  );
};

export default SubscriptionProfilePage;
