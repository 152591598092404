import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import DateRangeIcon from 'mdi-react/DateRangeIcon';

const ProfileSession = ({
  title,
  description,
}) => (
  <Col md={12} xl={12} xs={12}>
    <Card className="dashboard">
      <CardBody className="dashboard__card-widget">
        <div className="mobile-app-widget">
          <div className="mobile-app-widget__top-line mobile-app-widget__top-line--pink">
            <p className="mobile-app-widget__total-stat color_primary">{title}</p>
            <DateRangeIcon className="dashboard__trend-icon" />
          </div>
          <div className="mobile-app-widget__title">
            <h5>{description}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default ProfileSession;
