import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { useEmployee } from '@/hooks/useEmployees';
import { userPerfil } from '../../../../shared/helpers';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;


const EmployeeMain = () => {
  const { employee } = useEmployee();
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{employee.Nome}</p>
              <p className="profile__work">{employee.Celular ?? 'Sem Contato'}</p>
              <p className="profile__work">{employee.Email ?? 'No Data'}</p>

            </div>
          </div>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{employee.Notificacoes ? 'Nao' : 'Sim'}</p>
              <p className="profile__stat-title">Notificacoes</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{userPerfil[employee.UsuarioPerfil]}</p>
              <p className="profile__stat-title">Perfil</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{employee.OcultarMenuRelatorio ? 'Sim' : 'Não'}</p>
              <p className="profile__stat-title">Menu Relatorio</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default EmployeeMain;
