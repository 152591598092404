/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';


export const getAllByCompanyId = async (companyId) => {
    try {
        const { data } = await apiCustom.get(`/employees/allEmployes/${companyId}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};


export const getAllEmployeesByCompanyId = async (companyId, filterParams) => {
    try {
        const { data } = await apiCustom.get(`/employees/${companyId}`, {
            params: {
                ...filterParams,
                limit: 5,
            },
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getEmployeeByIdRef = async (employeeIdRef) => {
    try {
        const { data } = await apiCustom.get(`/employees/byIdRef/${employeeIdRef}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const putEmployee = async (employeeIdRef, employeeData) => {
    try {
        const { data } = await apiCustom.put(`/employees/byIdRef/${employeeIdRef}`, employeeData);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};
