import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router';
import { Card, CardBody, Col } from 'reactstrap';
import { useCompany } from '@/hooks/useCompany';
import { useCampaign } from '@/hooks/useCampaign';
import ErrorBoundary from '@/shared/components/ErrorBoundary';
import { CardSkeleton } from '@/shared/components/skeleton';
import CampaignCard from './CampaignCard';

const ListCampaigns = ({ campaigns }) => {
  const params = useParams();
  const { loadCampaignsByCompanyId, isLoading } = useCampaign();
  const { company } = useCompany();

  const onPageChanged = async ({ selected }) => {
    const data = selected + 1;
    await loadCampaignsByCompanyId(company.Id, {
      page: data,
    });
  };

  const memoTitle = useMemo(() => {
    if (!isLoading && !campaigns.data.length) return 'Nenhum resultado encontrado';
    return 'Campanhas da empresa';
  }, [isLoading, campaigns]);


  return (
    <Col md={12} lg={4} xl={12}>
      <Card className="card--not-full-height">
        <ErrorBoundary>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{memoTitle}</h5>
            </div>
            {isLoading ? <CardSkeleton />
              : (
                campaigns.data.map(({
                  IdRef,
                  Titulo,
                  Status,
                  DataAgendamento,
                }) => (
                  <CampaignCard
                    key={IdRef}
                    name={Titulo}
                    link={`/company/${params.id}/campaign/${IdRef}`}
                    scheduleDate={DataAgendamento}
                    status={Status ? 'Ativo' : 'Desativo'}
                  />
                ))
              )}
            <ReactPaginate
              className="pagination"
              previousClassName="pagination__link pagination__link--arrow"
              nextClassName="pagination__link pagination__link--arrow"
              pageClassName="pagination__item page-item"
              pageLinkClassName="pagination__link page-link"
              activeClassName="pagination__item page-item active"
              breakLabel="..."
              nextLabel=">"
              onPageChange={onPageChanged}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(campaigns.totalCount / Number(campaigns.limit))}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </CardBody>

        </ErrorBoundary>
      </Card>
    </Col>
  );
};

export default ListCampaigns;
