/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const getAllCompanies = async () => {
    try {
        const { data } = await apiCustom.get('/companies');
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAllCompaniesByFilter = async (filterParams) => {
    try {
        const { data } = await apiCustom.get('/companies/filter', {
            params: {
                ...filterParams,
            },
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getOneCompanyByIdRef = async (idRef) => {
    try {
        const { data } = await apiCustom.get(`/companies/${idRef}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

