/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import {
  getAllData, getAttendancesActivesAndDesactivesByWeek, getAttendancesActivesAndDesactivesByMonth, getAttendancesActivesAndDesactivesDay,
} from '@/shared/services/index';
import PropTypes from 'prop-types';

const DashboardContext = createContext({});

const DashboardProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    servicesOpen: 0,
    servicesClosed: 0,
  });

  const [listServicesByWeek, setListServicesByWeek] = useState({
    listServicesOpenByWeek: [],
    listServicesClosedByWeek: [],
  });

  const [listServicesByMonth, setListServicesByMonth] = useState({
    listServicesOpenByMonth: [],
    listServicesClosedByMonth: [],
  });

  const [listServicesByDay, setListServicesByDay] = useState({
    listServicesOpenByDay: [],
    listServicesClosedByDay: [],
  });

  const loadData = async () => {
    setIsLoading(true);
    try {
      const { payload } = await getAllData();
      setData({
        ...data,
        ...payload.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err);
      }
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllDataServicesOpenAndClosedByMonth = async (month, year) => {
    setIsLoading(true);
    try {
      const { payload } = await getAttendancesActivesAndDesactivesByMonth(month, year);
      setListServicesByMonth({
        ...listServicesByMonth,
        ...payload.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err);
      }
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllDataServicesOpenAndClosedByWeek = async (day = 1, month, year) => {
    setIsLoading(true);
    try {
      const { payload } = await getAttendancesActivesAndDesactivesByWeek(day, month, year);
      setListServicesByWeek({
        ...listServicesByWeek,
        ...payload.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err);
      }
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAnalitycsDataReferenceByPastMonth = async (day = 1, month, year, opened, closed) => {
    setIsLoading(true);
    let pastYear = year;
    if (month === 12) {
      pastYear = year - 1;
    }
    try {
      const { payload } = await getAttendancesActivesAndDesactivesDay(day, (month - 1), pastYear);
      const countOpenPastMonth = payload.data.listServicesOpenByDay.length;
      const countClosedPastMonth = payload.data.listServicesClosedByDay.length;

      const countOpenCurrentMonth = opened;
      const countClosedCurrentMonth = closed;

      const percentOpen = ((countOpenPastMonth - countOpenCurrentMonth) / countOpenPastMonth) * (100 / 100);
      const percentClosed = ((countClosedPastMonth - countClosedCurrentMonth) / countClosedPastMonth) * (100 / 100);
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err);
      }
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };





  const loadAllDataServicesOpenAndClosedByDay = async (day = 1, month, year) => {
    setIsLoading(true);
    try {
      const { payload } = await getAttendancesActivesAndDesactivesDay(day, month, year);
      setListServicesByDay({
        ...listServicesByDay,
        ...payload.data,
      });

      await loadAnalitycsDataReferenceByPastMonth(day, month, year, payload.data.listServicesOpenByDay.length, payload.data.listServicesClosedByDay.length);
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err);
      }
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };






  const valuesContext = {
    isLoading,
    error,
    data,
    listServicesByWeek,
    listServicesByMonth,
    listServicesByDay,
    // Functions
    loadData,
    loadAllDataServicesOpenAndClosedByDay,
    loadAllDataServicesOpenAndClosedByWeek,
    loadAllDataServicesOpenAndClosedByMonth,
  };

  return (
    <DashboardContext.Provider value={valuesContext}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node,
};

const useDashboard = () => useContext(DashboardContext);

export { DashboardProvider, useDashboard };
