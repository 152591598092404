import React, { useState, useMemo } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import showResults from '@/utils/showResults';
import { useAttendance } from '@/hooks/useAttendance';
import AttendanceSettings from './AttendanceSettings';

const AttendanceTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { attendance } = useAttendance();
 

  const memoInitialValues = useMemo(() => {
    if (attendance) {
      return {
        client: attendance.ChatNome ?? 'Sem Nome',
        message: attendance.ChatUltimaMensagem,
        contact: attendance.ChatIdExterno,

      };
    } 
    return {
      client: '',
      message: '',
      contact: '',
    };
  }, [attendance]);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };


  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Informações
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <AttendanceSettings
                  onSubmit={showResults}
                  initialValues={memoInitialValues}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default AttendanceTabs;
