/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from 'react';
import {
    Button, Col, Container, Row,
} from 'reactstrap';
import BackButton from '@/shared/components/button/BackButton';

const SessionCompanyPage = () => (
    <Container>
        <BackButton />
        <embed
            width="100%"
            height="600"
            src={`https://app2.ihelpchat.com?random=${(new Date()).getTime() + Math.floor(Math.random() * 1000000)}`}
        />
    </Container>
);

export default SessionCompanyPage;
