/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { useCampaign } from '@/hooks/useCampaign';
import Loading from '@/shared/components/Loading';
import CampaignMain from './components/CampaignMain';
import CampaignTabs from './components/CampaignTabs';
import CampaignSession from './components/CampaignSession';
import BackButton from '../../../shared/components/button/BackButton';


const CampaignProfilePage = () => {
  const { isLoading, loadCampaignByIdRef, campaign } = useCampaign();
  const paramRoute = useParams();
  const history = useHistory();

  useEffect(() => {
    if (paramRoute && paramRoute.id) {
      loadCampaignByIdRef(paramRoute.idCampaign);
    }
  }, [paramRoute]);

  if (isLoading || !campaign) return <Loading />;

  const countMessagesSends = campaign?.CampanhaContato.reduce((acc, curr) => {
    if (curr.Enviado) {
      acc += 1;
    }
    return acc;
  }, 0);


  const countMessagesView = campaign?.CampanhaContato.reduce((acc, curr) => {
    if (curr.Status) {
      acc += 1;
    }
    return acc;
  }, 0);

  const calculatePercentageMessagesSends = () => ((countMessagesSends) * 100) / campaign.CampanhaContato.length;
  const calculatePercentageMessagesViewed = () => ((countMessagesView) * 100) / campaign.CampanhaContato.length;

  return (
    <Container>
      <BackButton handleClick={history.goBack} />
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <CampaignMain />
            </Row>
            <Row>
              <Col md={12} lg={12} xl={6}>
                <CampaignSession
                  description="Mensagens Enviadas"
                  title={countMessagesSends || 0}
                  showProgressBar
                  percentageProgress={calculatePercentageMessagesSends()}
                />
              </Col>

              <Col md={12} lg={12} xl={6}>
                <CampaignSession
                  showProgressBar
                  percentageProgress={calculatePercentageMessagesViewed()}
                  description="Mensagens Visualizadas"
                  title={countMessagesView || 0}
                />
              </Col>

              <Col md={12} lg={12} xl={6}>
                <CampaignSession description="Nao enviadas" title={campaign.CampanhaContato.length - countMessagesSends || 0} />
              </Col>

              <Col md={12} lg={12} xl={6}>
                <CampaignSession description="Departamento" title={campaign.Departamento.Titulo || 'Sem Departamento'} />
              </Col>
            </Row>
          </Col>
          <CampaignTabs />
        </Row>
      </div>
    </Container>
  );
};

export default CampaignProfilePage;
