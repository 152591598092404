/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import {
  getAllByCompanyId, getAllEmployeesByCompanyId, getEmployeeByIdRef, putEmployee,
} from '@/shared/services/index';
import PropTypes from 'prop-types';

const EmployeeContext = createContext({});

const EmployeeProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });
  const [employee, setEmployee] = useState(null);
  const [error, setError] = useState(false);

  const loadAllByCompanyId = async (companyId) => {
    setAllEmployees([]);
    setIsLoading(true);
    try {
      const { payload } = await getAllByCompanyId(
        companyId,
      );
      setAllEmployees(payload.data);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };


  const loadEmployeesByCompanyId = async (companyId, filterParams = '') => {
    setIsLoading(true);
    try {
      const { payload } = await getAllEmployeesByCompanyId(
        companyId,
        filterParams,
      );
      setEmployees(payload.data);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadEmployeeByIdRef = async (employeeIdRef) => {
    setIsLoading(true);
    try {
      const { payload } = await getEmployeeByIdRef(employeeIdRef);
      setEmployee(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEmployee = async (employeeIdRef, employeeData) => {
    setIsLoading(true);
    try {
      await putEmployee(employeeIdRef, employeeData);
      setEmployee({
        ...employee,
        ...employeeData,
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };


  const setClean = () => {
    setAllEmployees([]);
  };

  const valuesContext = {
    isLoading,
    allEmployees,
    employees,
    employee,
    error,
    // Functions
    loadAllByCompanyId,
    loadEmployeesByCompanyId,
    loadEmployeeByIdRef,
    updateEmployee,
    setClean,
  };

  return (
    <EmployeeContext.Provider value={valuesContext}>
      {children}
    </EmployeeContext.Provider>
  );
};

EmployeeProvider.propTypes = {
  children: PropTypes.node,
};

const useEmployee = () => useContext(EmployeeContext);

export { EmployeeProvider, useEmployee };
