import React from 'react';
import { Route } from 'react-router-dom';
import AppDashboard from '@/containers/Dashboards/App/index';
import CompanyProfilePage from '@/containers/Companies/Profile/index';
import CampaignProfilePage from '@/containers/Companies/Campaigns/CampaignProfilePage';
import ChannelProfilePage from '@/containers/Companies/Channels/ChannelProfilePage';
import AttendanceProfilePage from '@/containers/Companies/Attendance/AttendanceProfilePage';
import EmployeeProfilePage from '@/containers/Companies/Employees/EmployeeProfilePage';
import SubscriptionProfilePage from '@/containers/Companies/Subscriptions/SubscriptionProfilePage';
import ChannelsPage from '@/containers/Dashboards/Channels';
import SessionCompanyPage from '@/containers/Companies/Session';
import DashboardPage from '@/containers/Dashboards/Dashboard';
import Layout from '../../../Layout/index';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={AppDashboard} />
      <Route exact path="/dashboard" component={DashboardPage} />
      <Route exact path="/channels" component={ChannelsPage} />
      <Route exact path="/company/:id" component={CompanyProfilePage} />
      <Route exact path="/company/:id/campaign/:idCampaign" component={CampaignProfilePage} />
      <Route exact path="/company/:id/channel/:idChannel" component={ChannelProfilePage} />
      <Route exact path="/company/:id/employee/:idEmployee" component={EmployeeProfilePage} />
      <Route exact path="/company/:id/attendance/:idAttendance" component={AttendanceProfilePage} />
      <Route exact path="/company/:id/subscriptions" component={SubscriptionProfilePage} />
      <Route exact path="/company/:id/session" component={SessionCompanyPage} />
    </div>
  </div>
);
