/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const getAllCampaignsByCompanyId = async (companyId, filterParams) => {
    try {
        const { data } = await apiCustom.get(`/campaigns/${companyId}`, {
            params: {
                ...filterParams,
                limit: 5,
            },
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getCampaignByIdRef = async (campaignIdRef) => {
    try {
        const { data } = await apiCustom.get(`/campaigns/byIdRef/${campaignIdRef}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const putCampaignStatus = async (campaignIdRef, statusNumber) => {
    try {
        const { data } = await apiCustom.put(`/campaigns/byIdRef/${campaignIdRef}/${statusNumber}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

