/* eslint-disable react/jsx-indent */
import React from 'react';
import {
    Card, CardBody, Progress,
} from 'reactstrap';
import DateRangeIcon from 'mdi-react/CubeSendIcon';

const CampaignSession = ({
    title,
    description,
    showProgressBar = false,
    percentageProgress = 0,
}) => (
    <Card className="dashboard">
        <CardBody className="dashboard__card-widget">
            <div className="mobile-app-widget">
                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--pink">
                    <p className="mobile-app-widget__total-stat color_primary">{title}</p>
                    <DateRangeIcon className="dashboard__trend-icon" />
                </div>
                <div className="mobile-app-widget__title">
                    <h5>{description}</h5>
                </div>

                {showProgressBar && (
                    <div className=" mt-4 progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top">
                        <Progress value={percentageProgress}><p className="progress__label">{percentageProgress}%</p></Progress>
                    </div>
                )}
            </div>
        </CardBody>
    </Card>
);

export default CampaignSession;
