/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import {
  CardBody, Col, Container, Row,
} from 'reactstrap';
import { useCompany } from '@/hooks/useCompany';
import ListCompanies from './components/ListCompanies';


const AppDashboard = () => {
  const { loadCompaniesByFilter, companies } = useCompany();

  useEffect(() => {
    loadCompaniesByFilter();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Empresas IHelp</h3>
        </Col>
      </Row>
      <Row>
        <CardBody>
          <ListCompanies companies={companies} />
        </CardBody>
      </Row>
    </Container>
  );
};


export default AppDashboard;
