import React, { useEffect, useCallback } from 'react';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import { useParams } from 'react-router';
import { useCompany } from '@/hooks/useCompany';
import { useEmployee } from '@/hooks/useEmployees';
import { getURLConnection } from '@/utils/helpers';
import { useAttendance } from '@/hooks/useAttendance';
import Loading from '@/shared/components/Loading';
import BackButton from '@/shared/components/button/BackButton';
import { useSubscription } from '@/hooks/useSubscription';
import { userPerfil } from '@/shared/helpers';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import ProfileSession from './components/ProfilesSession';

const CompanyProfilePage = () => {
  const style = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  };
  const {
    loadCompanyByIdRef, isLoading, company, setCleanCompany,
  } = useCompany();
  const { loadSubscriptionByCustomerId, resetContext } = useSubscription();
  const {
    allEmployees, loadAllByCompanyId, setClean,
  } = useEmployee();
  const { loadLastReceivedAndSendedMessageByCompanyId, lastData } = useAttendance();
  const paramRoute = useParams();

  useEffect(() => {
    let mounted = true;
    if (paramRoute && paramRoute.id) {
      loadCompanyByIdRef(paramRoute.id);
    }
    return () => {
      mounted = false;
      setCleanCompany();
    };
  }, [paramRoute]);

  useEffect(() => {
    if (company && company.Id) {
      loadLastReceivedAndSendedMessageByCompanyId(company.Id);
    }
  }, [company]);

  useEffect(() => {
    if (company && company.CustomerID) {
      loadSubscriptionByCustomerId(company.CustomerID);
    } else {
      resetContext();
    }
  }, [company]);

  useEffect(() => {
    let mounted = true;
    if (company && company.Id && mounted) {
      loadAllByCompanyId(company.Id);
    }

    return () => {
      mounted = false;
      setClean();
    };
  }, [company]);


  const formatedMessages = useCallback((messageDate) => {
    const date = new Date(messageDate).toISOString();
    const [date2, time] = date.split('T');

    const [year, month, day] = date2.split('-');
    const [time2, rest] = time.split('.');

    const newDate = `${day}/${month}/${year} ${time2}`;


    return newDate;
  }, []);


  const handleoOpenSystemClient = () => {
    const firstUserAdm = allEmployees.find(employee => userPerfil[employee.UsuarioPerfil] === 'Administrador');
    if (!firstUserAdm) return;

    try {
      const baseTO64 = btoa(`username=${firstUserAdm.UserName}&password=pX177&rF`);
      window.open(`${getURLConnection()}/login?session=${baseTO64}`, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading || !company) return <Loading />;


  return (
    <Container>
      <div style={style}>
        <BackButton />
        <Button
          style={{ marginLeft: '20px' }}
          onClick={handleoOpenSystemClient}
          color="primary"
          type="submit"
        >Conectar
        </Button>
      </div>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain />
              <ProfileSession
                title={lastData?.lastMessageSending ? formatedMessages(lastData?.lastMessageSending) : 'N/A'}
                description="Ultima mensagem enviada"
              />
              <ProfileSession
                title={lastData?.lastMessageReceived ? formatedMessages(lastData?.lastMessageReceived) : 'N/A'}
                description="Ultima mensagem recebida"
              />
            </Row>
          </Col>
          <ProfileTabs />
        </Row>
      </div>
    </Container>
  );
};

export default CompanyProfilePage;
