/* eslint-disable react/jsx-indent */
import React from 'react';

const CardProductItem = ({
    name, createdAt, price,
}) => (
    <div className="card-product">
        <div className="card-content">
            <h4>{name}</h4>
            <span>{createdAt}</span>
        </div>

        <div className="card-value">
            {price}
        </div>
    </div>
);

export default CardProductItem;
