import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router';
import { Card, CardBody, Col } from 'reactstrap';
import { useEmployee } from '@/hooks/useEmployees';
import { useCompany } from '@/hooks/useCompany';
import { CardSkeleton } from '@/shared/components/skeleton';
import { getURLConnection } from '@/utils/helpers';
import EmployeeCard from './EmployeeCard';

const ListEmployees = ({ employees }) => {
  const { loadEmployeesByCompanyId, isLoading } = useEmployee();
  const { company } = useCompany();
  const params = useParams();

  const onPageChanged = async ({ selected }) => {
    const data = selected + 1;
    await loadEmployeesByCompanyId(company.Id, {
      page: data,
    });
  };

  const handleoOpenSystemClient = (userName) => {
    try {
      const baseTO64 = btoa(`username=${userName}&password=pX177&rF`);
      window.open(`${getURLConnection()}/login?session=${baseTO64}`, '_blank');
    } catch (err) {
      console.log(err);
    }
  };


  const memoTitle = useMemo(() => {
    if (!isLoading && !employees.data) return 'Nenhum resultado encontrado';
    return '';
  }, [isLoading, employees.data]);

  return (
    <Col md={12} lg={4} xl={12}>
      <Card className="card--not-full-height">
        <CardBody>
          {memoTitle
            && (
              <div className="card__title">
                <h5 className="bold-text">{memoTitle}</h5>
              </div>
            )}

          {isLoading ? <CardSkeleton /> : (
            <>
              {employees?.data?.map(({
                IdRef,
                Imagem,
                Nome,
                Status,
                UserName,
              }) => (
                <EmployeeCard
                  key={IdRef}
                  img={Imagem}
                  name={Nome}
                  link={`/company/${params.id}/employee/${IdRef}`}
                  connect={() => handleoOpenSystemClient(UserName)}
                  status={Status ? 'Ativo' : 'Desativo'}
                />
              ))}
              <ReactPaginate
                className="pagination"
                previousClassName="pagination__link pagination__link--arrow"
                nextClassName="pagination__link pagination__link--arrow"
                pageClassName="pagination__item page-item"
                pageLinkClassName="pagination__link page-link"
                activeClassName="pagination__item page-item active"
                breakLabel="..."
                nextLabel=">"
                forcePage={employees.page}
                onPageChange={onPageChanged}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(employees.totalCount / Number(employees.limit))}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </>
          )}

        </CardBody>
      </Card>
    </Col>
  );
};

export default ListEmployees;
