import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import RegisterForm from '@/shared/components/login_register/LoginRegisterForm';


const Register = ({ history }) => {
  const [error, setError] = useState('');

  const registerAccount = (user) => {

  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Bem vindo ao Ihelp
              <span className="account__logo"> ihelp
                <span className="account__logo-accent">ADM</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Crie sua conta</h4>
          </div>
          <RegisterForm onSubmit={registerAccount} errorMessage={error} />
          <div className="account__have-account">
            <p>Ja possui uma conta? <NavLink to="/account/login">Login</NavLink></p>
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Register);
