import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import showResults from '@/utils/showResults';
import { useEmployee } from '@/hooks/useEmployees';
import { useCompany } from '@/hooks/useCompany';
import { useCampaign } from '@/hooks/useCampaign';
import { useAttendance } from '@/hooks/useAttendance';
import { useChannel } from '@/hooks/useChannel';
import ProfileSettings from './ProfileSettings';
import ListCampaigns from '../../Campaigns/components/ListCampaigns';
import ListChannels from '../../Channels/components/ListChannels';
import ListAttendances from '../../Attendance/components/ListAttendances';
import ListEmployees from '../../Employees/components/ListEmployees';


const ProfileTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { loadEmployeesByCompanyId, employees } = useEmployee();
  const { loadChannelsByCompanyId, channels } = useChannel();
  const { loadAttendanceByCompanyId, attendances } = useAttendance();
  const { loadCampaignsByCompanyId, campaigns } = useCampaign();
  const { company } = useCompany();


  const memoInitialValues = useMemo(() => {
    if (company) {
      return {
        username: company.Nome,
        email: company.Email,
        document: company.Documento,
      };
    }
    return {
      username: '',
      email: '',
      document: '',
    };
  }, [company]);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if (activeTab === '2') {
      loadEmployeesByCompanyId(company.Id);
    } else if (activeTab === '3') {
      loadChannelsByCompanyId(company.Id);
    } else if (activeTab === '4') {
      loadCampaignsByCompanyId(company.Id);
    } else if (activeTab === '5') {
      loadAttendanceByCompanyId(company.Id);
    }
  }, [activeTab, company.Id]);

  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Informações
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => handleToggle('2')}
                >
                  Usuários
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => handleToggle('3')}
                >
                  Canais
                </NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => handleToggle('4')}
                >
                  Campanhas
                </NavLink>
              </NavItem> */}

              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => handleToggle('5')}
                >
                  Atendimentos
                </NavLink>
              </NavItem> */}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                <ListEmployees employees={employees} />
              </TabPane>
              <TabPane tabId="3">
                <ListChannels channels={channels} />
              </TabPane>
              <TabPane tabId="4">
                <ListCampaigns campaigns={campaigns} />
              </TabPane>
              <TabPane tabId="5">
                <ListAttendances attendances={attendances} />
              </TabPane>
              <TabPane tabId="1">
                <ProfileSettings
                  onSubmit={showResults}
                  initialValues={memoInitialValues}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ProfileTabs;
