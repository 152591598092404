import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { useChannel } from '@/hooks/useChannel';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ChannelMain = () => {
  const { channel } = useChannel();
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{channel.Titulo}</p>
              <p className="profile__work">{channel.IdContato}</p>

            </div>
          </div>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{channel.Desconectado ? 'Desconectado' : 'Conectado'}</p>
              <p className="profile__stat-title">Conexão</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{channel.ExibirStatusDoAparelho ? 'Sim' : 'Não'}</p>
              <p className="profile__stat-title">Exibir Status</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{channel.LeituraMensagem ? 'Sim' : 'Não'}</p>
              <p className="profile__stat-title">Leitura</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default ChannelMain;
