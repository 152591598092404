/* eslint-disable camelcase */
import common_pt from './pt/common.json';
import common_en from './en/common.json';

import errors_pt from './pt/errors.json';
import errors_en from './en/errors.json';

export default {
  en: {
    common: common_en,
    errors: errors_en,
  },
  pt: {
    common: common_pt,
    errors: errors_pt,
  },

};
