/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const getAllAttendancesByCompanyId = async (companyId, filterParams) => {
    try {
        const { data } = await apiCustom.get(`/attendances/${companyId}`, {
            params: {
                ...filterParams,
                limit: 5,
            },
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getLastReceivedAndSendedMessageByCompanyId = async (companyId) => {
    try {
        const { data } = await apiCustom.get(`/attendances/${companyId}/getFirstAndLastMessage`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAttendanceByIdRef = async (attendanceIdRef) => {
    try {
        const { data } = await apiCustom.get(`/attendances/byIdRef/${attendanceIdRef}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

