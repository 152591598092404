/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import {
  getAllAttendancesByCompanyId,
  getAttendanceByIdRef,
  getLastReceivedAndSendedMessageByCompanyId,
} from '@/shared/services/index';

const AttendanceContext = createContext({});

const AttendanceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [attendances, setAttendances] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });
  const [lastData, setLastData] = useState({
    lastMessageReceived: null,
    lastMessageSending: null,
    countAttendancesOpenning: 0,
  });
  const [attendance, setAttendance] = useState(null);

  const loadAttendanceByCompanyId = async (companyId, filterParams = '') => {
    if (filterParams && filterParams.page === 1) setIsLoading(true);
    try {
      const { payload } = await getAllAttendancesByCompanyId(
        companyId,
        filterParams,
      );
      setAttendances(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadLastReceivedAndSendedMessageByCompanyId = async (companyId) => {
    setIsLoading(true);
    try {
      const { payload } = await getLastReceivedAndSendedMessageByCompanyId(
        companyId,
      );

      setLastData(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAttendanceByIdRef = async (attendanceIdRef) => {
    setIsLoading(true);
    try {
      const { payload } = await getAttendanceByIdRef(attendanceIdRef);
      setAttendance(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const valuesContext = {
    isLoading,
    attendances,
    attendance,
    lastData,
    // Functions
    loadAttendanceByCompanyId,
    loadLastReceivedAndSendedMessageByCompanyId,
    loadAttendanceByIdRef,
  };

  return (
    <AttendanceContext.Provider value={valuesContext}>
      {children}
    </AttendanceContext.Provider>
  );
};

const useAttendance = () => useContext(AttendanceContext);

export { AttendanceProvider, useAttendance };
