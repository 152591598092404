import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { useAttendance } from '@/hooks/useAttendance';
import Loading from '@/shared/components/Loading';
import AttendanceMain from './components/AttendanceMain';
import AttendanceTabs from './components/AttendanceTabs';


const AttendanceProfilePage = () => {
  const { isLoading, loadAttendanceByIdRef, attendance } = useAttendance();
  const paramRoute = useParams();

  useEffect(() => {
      if (paramRoute && paramRoute.id) {
        loadAttendanceByIdRef(paramRoute.idAttendance);
      }
  }, [paramRoute]);


  if (isLoading || !attendance) return <Loading />;

  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <AttendanceMain />
            </Row>
          </Col>
          <AttendanceTabs />
        </Row>
      </div>
    </Container>
);
  };

export default AttendanceProfilePage;
