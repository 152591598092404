import React from 'react';
import { Link } from 'react-router-dom';

import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const CampaignCard = ({
   name, status, scheduleDate, link,
}) => (
  <div className="project-member">
    <div className="project-member__avatar-wrap">
      <img src={Ava} alt="imagem do empregado" />
    </div>
    <div>
      <p className="project-member__name">{name}</p>
      <p className="project-member__post">{status}</p>
      <p className="project-member__post">{scheduleDate}</p>
    </div>
    <Link
      className="project-member__btn btn btn-outline-primary btn-sm icon"
      to={link}
    ><ArrowRightBoxIcon />
    </Link>
  </div>
);



export default CampaignCard;
