/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import { getAllCampaignsByCompanyId, getCampaignByIdRef, putCampaignStatus } from '@/shared/services/index';
import PropTypes from 'prop-types';

const CampaignContext = createContext({});

const CampaignProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({
    totalCount: 0,
    page: 1,
    limit: 10,
    data: [],
  });
  const [campaign, setCampaign] = useState(null);
  const [error, setError] = useState(false);

  const loadCampaignsByCompanyId = async (companyId, filterParams = '') => {
    setIsLoading(true);
    try {
      const { payload } = await getAllCampaignsByCompanyId(
        companyId,
        filterParams,
      );
      setCampaigns(payload.data);
    } catch (err) {
      throw new Error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const loadCampaignByIdRef = async (companyId) => {
    setIsLoading(true);
    try {
      const { payload } = await getCampaignByIdRef(companyId);
      setCampaign(payload.data);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStatusCampaign = async (campaignIdRef, statusNumber) => {
    setIsLoading(true);
    try {
      const { payload } = await putCampaignStatus(campaignIdRef, statusNumber);
      setCampaign({
        ...campaign,
        ...payload.data,
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const valuesContext = {
    isLoading,
    error,
    campaigns,
    campaign,
    // Functions
    loadCampaignsByCompanyId,
    loadCampaignByIdRef,
    updateStatusCampaign,
  };

  return (
    <CampaignContext.Provider value={valuesContext}>
      {children}
    </CampaignContext.Provider>
  );
};

CampaignProvider.propTypes = {
  children: PropTypes.node,
};

const useCampaign = () => useContext(CampaignContext);

export { CampaignProvider, useCampaign };
