import React, { useEffect, useState } from 'react';
import {
  CardBody, Col, Container, Row,
} from 'reactstrap';
import { useChannel } from '@/hooks/useChannel';
import Loading from '@/shared/components/Loading';
import ListChannels from './components/ListChannels';


const ChannelsPage = () => {
  const { loadChannelsByFilter, allChannels, isLoading } = useChannel();
  const [time, setTime] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     await loadChannelsByFilter();
  //   }, 8000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Canais IHelp</h3>
        </Col>
      </Row>

      <Row>
        <CardBody>
          <ListChannels />
        </CardBody>
      </Row>
    </Container>
  );
};


export default ChannelsPage;
