/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const login = async (username, password) => {
    try {
        const { data } = await apiCustom.post('/auth/login', {
            username,
            password,
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
};


