/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Table } from 'reactstrap';
import classNames from 'classnames';

const Ava = `${process.env.PUBLIC_URL}/img/logo.png`;

const header = [
    { id: 0, title: '' },
    { id: 1, title: 'Contato' },
    { id: 2, title: 'Enviado' },
    { id: 3, title: 'Variaveis' },
];

const _CampaignContacts = ({ contacts }) => {
    const formatedVariables = (variables) => {
        const parseVariables = JSON.parse(variables);
        return parseVariables.map(variable => `${variable.value}`).join(': ');
    };

    return (

        <Table
            className="table--bordered"
        >
            <thead>
                <tr>
                    {header.map(item => (
                        <th key={item.id}>{item.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {contacts?.map(item => (
                    <tr key={item.Id}>
                        <td>
                            <div
                                className={classNames(
                                    'project-member__avatar-wrap',
                                    {
                                        color__status__active: item.Enviado,
                                        color__status__desactive: !item.Enviado,
                                    },
                                )}
                            >
                                <img
                                    src={Ava}
                                    alt="imagem do empregado"
                                    style={{ objectFit: 'contain' }}
                                />
                            </div>
                        </td>
                        <td>{item.Contato}</td>
                        <td>{item.Enviado ? 'Enviado' : 'Nao Enviado'}</td>
                        <td>{formatedVariables(item.Variaveis)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

const CampaignContacts = React.memo(_CampaignContacts);

export default CampaignContacts;
