import React, { useState, useMemo } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useChannel } from '@/hooks/useChannel';
import { useHistory } from 'react-router';
import ChannelSettings from './ChannelSettings';

const ChannelTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { channel, updateChannel } = useChannel();
  const { goBack } = useHistory();

  const memoInitialValues = useMemo(() => {
    if (channel) {
      return {
        title: channel.Titulo,
        description: channel.Descricao,
        status: channel.Status,
        configurations: channel.PropriedadeCanal,

      };
    }
    return {
      title: '',
      description: '',
      status: '',
      configurations: [],
    };
  }, [channel]);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      titulo: values.title,
      descricao: values.description,
      idContato: channel.IdContato,
      DepartamentoDefaultId: Number(values.DepartamentoDefaultId),
      imagemPerfil: channel.ImagemPerfil,
      PropriedadeCanal: values.configurations.map(configuration => ({
        Id: configuration.Id ?? 0,
        InputType: configuration.InputType,
        Label: configuration.Label,
        Nome: configuration.Nome,
        Valor: values[configuration.Nome],
        PropriedadeId: configuration.PropriedadeId,
      })),
    };
    delete data.title;
    delete data.description;
    delete data.configurations;

    try {
      await updateChannel(channel.IdRef, data);
      goBack();
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Informações
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ChannelSettings
                  onSubmit={handleSubmit}
                  initialValues={memoInitialValues}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ChannelTabs;
