/* eslint-disable react/jsx-indent */
import React from 'react';
import {
    Card, CardBody, Progress,
} from 'reactstrap';
import TextAccountIcon from 'mdi-react/TextAccountIcon';
import CalendarAccountIcon from 'mdi-react/CalendarAccountIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import ListStatusIcon from 'mdi-react/ListStatusIcon';
import PrizeIcon from 'mdi-react/PrizeIcon';

// Calendar / Status / Card / Value

const CardData = ({
    value, title = '', showProgressBar = false, typeIcon = 'calendar',
}) => (
    <Card>
        <CardBody className="dashboard__card-widget dashboard_subscriptions">
            <div className="mobile-app-widget">
                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                    <p className="mobile-app-widget__total-stat">{value}</p>
                    {typeIcon === 'text' && <TextAccountIcon className="dashboard__trend-icon" />}
                    {typeIcon === 'calendar' && <CalendarAccountIcon className="dashboard__trend-icon" />}
                    {typeIcon === 'credit' && <CreditCardIcon className="dashboard__trend-icon" />}
                    {typeIcon === 'status' && <ListStatusIcon className="dashboard__trend-icon" />}
                    {typeIcon === 'price' && <PrizeIcon className="dashboard__trend-icon" />}

                </div>
                <div className="mobile-app-widget__title">
                    <h5>{title}</h5>
                </div>
                {showProgressBar && (
                    <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top">
                        <Progress value={45}><p className="progress__label">45%</p></Progress>
                    </div>
                )}

            </div>
        </CardBody>
    </Card>
);

export default CardData;
