/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from 'react';
import {
    Button,
    Card, CardBody, Col,
} from 'reactstrap';
import { useChannel } from '@/hooks/useChannel';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ChannelConnect = () => {
    const { channel, tryConnectChannel } = useChannel();
    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody className="profile__card">
                    <div className="profile__information">
                        <img src="https://br.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market/generator/dist/generator/assets/images/websiteQRCode_noFrame.png" alt="" />
                    </div>
                    <div className="profile__stats profile_channel">
                        <div className="profile__stat">
                            <p className="profile__stat-title">Atenção: Antes de conectar marque as mensagens já respondidas como lida em seu aparelho</p>
                        </div>

                        <Button
                            color={channel.status ? 'primary' : ''}
                            onClick={() => tryConnectChannel(channel.Id)}
                            type="button"
                        >
                            {channel.status ? 'Conectado' : 'Desconectado'}
                        </Button>

                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};
export default ChannelConnect;
