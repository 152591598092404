/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const getAllData = async () => {
    try {
        const { data } = await apiCustom.get('/dashboard');
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAttendancesActivesAndDesactivesByMonth = async (month, year) => {
    try {
        const { data } = await apiCustom.get(`/dashboard/getAttendacesByMonth/${ month }/${ year}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAttendancesActivesAndDesactivesByWeek = async (day = 1, month, year) => {
    try {
        const { data } = await apiCustom.get(`/dashboard/getAttendacesByWeek/${day}/${ month }/${ year}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAttendancesActivesAndDesactivesDay = async (day = 1, month, year) => {
    try {
        const { data } = await apiCustom.get(`/dashboard/getAttendacesByDay/${day}/${ month }/${ year}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};


