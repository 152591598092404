import React from 'react';
import { Link } from 'react-router-dom';

import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon';
import classNames from 'classnames';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ChannelCard = ({
  name, status, contact, link,
}) => (
  <div className="project-member">
    <div
      className={classNames(
        'project-member__avatar-wrap',
        {
          color__status__active: status,
          color__status__desactive: !status,
        },
      )}
    >
      <img src={Ava} alt="imagem do empregado" />
    </div>
    <div>
      <p className="project-member__name">{name}</p>
      <p className="project-member__post">{status ? 'Ativo' : 'Desativo'}</p>
      <p className="project-member__post">{contact}</p>
    </div>
    <Link
      className="project-member__btn btn btn-outline-primary btn-sm icon"
      to={link}
    ><ArrowRightBoxIcon />
    </Link>
  </div>
);



export default ChannelCard;
